<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      do-route
      do-route-filters
      no-meta-check
      route-base="/resources/registers"
      resource="registers"
      show-search-filter
      :headers="headers"
      :show-operations="false"
      :search-filters="filtersList"
      :resource-query="resourceQuery"
      :transform-fetched-data="checkOnboarding"
      @loading-error="handleLoadingError"
    >
      <template #tools>
        <div>
          <a
            class="text-th-secondary text-sm"
            :href="contactLink"
            target="_blank"
          >
            {{ $t('pages.registers.all.contact_for_more') }}
          </a>
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import get from 'just-safe-get'
import { mapState, mapGetters } from 'vuex'
import { whitelabels, tillhubLinks, localsearchLinks } from '@/constants'
import onboardingMixin from '@/mixins/onboarding'

export default {
  name: 'Registers',
  metaInfo() {
    return {
      title: this.$t('pages.registers.title')
    }
  },
  setup() {
    const { onboardingConfirmed } = onboardingMixin()
    return {
      onboardingConfirmed
    }
  },
  computed: {
    ...mapGetters({
      isOnboardingRoute: 'Config/getIsOnboardingRoute',
      currentLocation: 'Config/getCurrentLocation'
    }),
    ...mapState({
      whitelabel: (state) => state.Auth.whitelabel
    }),
    headers() {
      return [
        {
          field: 'register_number',
          label: this.$t('pages.registers.all.headers.register_number'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'name',
          label: this.$t('pages.registers.all.headers.name'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'branch_number',
          label: this.$t('pages.registers.all.headers.branch_number'),
          minWidth: 60,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'device_configuration.fiscal_signing_type',
          label: this.$t('pages.registers.all.headers.fiskalyState.title'),
          featureFlag: 'fiscalisation',
          minWidth: 120,
          truncate: true,
          formatter: this.fisacalStatus
        },
        {
          field: 'type',
          label: this.$t('pages.registers.all.headers.type'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => this.clientType[row.type]
        },
        {
          field: 'client_version',
          label: this.$t('pages.registers.all.headers.client_version'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        }
      ].filter((b) =>
        b.featureFlag ? this.$isFeatureEnabled(b.featureFlag) : true
      )
    },
    contactLink() {
      return this.whitelabel === whitelabels.LOCALSEARCH
        ? localsearchLinks.contact
        : tillhubLinks.contact
    },
    filtersList() {
      return [
        {
          name: 'register_number',
          type: 'remote-search-select',
          label: this.$t('pages.registers.all.headers.register_number'),
          resource: 'registers',
          fetchHandler: 'getAll',
          optionsValue: 'register_number',
          computedFields: ['register_number', 'name'],
          modifyQuery: (q) => ({
            q,
            deleted: false,
            fields: ['register_number', 'name']
          })
        }
      ]
    },
    resourceQuery() {
      return {
        query: { deleted: false, embed: 'branch', branch: this.currentLocation }
      }
    },
    clientType() {
      return {
        'gastropos-mobile': this.$t(
          'pages.registers.all.types.gastropos-mobile'
        ),
        'fullpos-mobile': this.$t('pages.registers.all.types.fullpos-mobile'),
        'fullpos-stationary': this.$t(
          'pages.registers.all.types.fullpos-stationary'
        ),
        'litepos-mobile': this.$t('pages.registers.types.all.litepos-mobile'),
        eda: this.$t('pages.registers.all.types.eda')
      }
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', async () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    checkOnboarding(data) {
      if (
        this.isOnboardingRoute &&
        !this.onboardingConfirmed('register') &&
        data.length === 1
      ) {
        this.$router.push(`/resources/registers/${data[0].id}`)
      }
      if (this.isOnboardingRoute) {
        // Unset onboarding once entered in the page
        this.$store.dispatch('Config/setIsOnboardingRoute', false)
      }
      return data
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.registers.title')
        })
      })
    },
    fisacalStatus(row) {
      const fiskalType = get(row, 'device_configuration.fiscal_signing_type')

      return fiskalType
        ? this.$t('pages.registers.all.headers.fiskalyState.connected')
        : this.$t('pages.registers.all.headers.fiskalyState.uninitialized')
    }
  }
}
</script>
